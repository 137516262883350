@import '../variables.scss';

.other-reveal {
    opacity: 0;
}

.other-reveal.active {
    opacity: 1;
}

.wrapper {
    // background: $red;
    font-weight: 700;
    color: $dark-blue;
    margin-bottom: 7rem;
    position: relative;
    overflow: hidden;
    transition: .1s ease-in-out;
    
    .section-divider {
        display: flex;
        justify-content: center;
        // position: absolute;
        display: flex;
        gap: 10rem;
        font-size: 1vmin;
        
        div {
            font-size: 8em;
            flex-shrink: 0;
        }
    }
    
    @media screen and (min-width: $tablet-width){
        margin-bottom: 18rem;
        // display: block;
        // font-size: 7rem;
    }
}