@import '../variables.scss';

.background {
    position: fixed;
    inset: 0;
    z-index: -1;
    opacity: .25;

    .vertical {
        position: absolute;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 100%;

        .vertical-lines {
            width: 1px;
            height: 0%;
            background: $red;

            @media (min-width: $tablet-width){
                width: 0.25px;
                }
        }
    }

    .horizontal {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .horizontal-lines {
            height: 1px;
            width: 0%;
            background: $red;
            
            @media (min-width: $tablet-width){
                height: 0.25px;
                }
        }
    }
}