@import '../variables.scss';

//PROJECT PAGE

.Project-layout {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    row-gap: 4rem;
    margin-bottom: 12rem;

    @media screen and (min-width: $tablet-width) {
        row-gap: 12rem;
    }

    @media screen and (min-width: $desktop-width) {
        max-width: 1400px;
        margin: 0 auto;
        margin-bottom: 7rem;
    }

    .project-card {
        opacity: 1;
        transition: 150ms ease;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: $dark-blue;
        height: auto;
        position: relative;
        transform: translateY(30px);

        @media screen and (min-width: $tablet-width) {
            flex-direction: row;
            gap: 6rem;
            transform: translateY(50px);
            opacity: 0;

            &::before {
                height: 0%;
                width: 0.5px;
            }

            &::after {
                height: 0.5px;
                width: 0%;
            }

            &:nth-child(even) {

                justify-content: space-evenly;
                flex-direction: row-reverse;

                // .img-container {
                //     order: 2;
                // }

                // .project-card-info {
                //     order: 1;
                // }

                &::before,
                &::after {

                    left: -2rem;

                }
            }

            &:hover {

                &::before {
                    height: 100%;
                    width: 5px;
                }

                &::after {
                    height: 5px;
                    width: 30%;
                }
            }


        }

        .project-card-info {
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            transition: .3s ease-out;

            .project-card-status {
                font-weight: 300;
                margin-top: .5rem;
                opacity: .75;
            }

            .project-card-info-inner {
                position: sticky;
                top: 4rem;
            }


            .project-card-title {
                font-size: 1.5rem;
                height: auto;

                @media screen and (min-width: $desktop-width) {
                    font-size: 2rem;
                }

            }

            .project-card-category {
                font-size: .9rem;

                @media screen and (min-width: $tablet-width) {
                    font-size: 1rem;
                }

                @media screen and (min-width: $desktop-width) {
                    font-size: 1.5rem;
                }
            }
        }


        .img-container {
            overflow: hidden;
            height: 100%;
            border: $main-border;
            position: relative;
            // display: flex;
            // align-items: center;
            transition: .3s ease-out;

            @media screen and (min-width: $tablet-width) {

                max-width: 50%;
                // max-height: 640px;

            }

            @media screen and (min-width: $desktop-width) {

                max-width: 640px;

            }

            img {
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}

.project-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: $red;
    transform: translateY(100%);
}

.project-overlay.second {
    background: $dark-blue;
    z-index: 998;
}

// PROJECT PAGE CONTENT

.project {
    transform: translateY(100%);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: $light-color;
    border: $main-border;
    opacity: 1;
    color: $dark-blue;
    overflow: hidden;
    overflow-y: auto;
    min-height: 100vh;
    transform-origin: center bottom;

    .project-padding {
        padding: 0 1rem;
        padding-bottom: 4rem;
    }

    .rights {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 3rem;
    }

    &::-webkit-scrollbar {
        display: none;
    }

    .is-appearing {
        opacity: 0;
        transform: translateY(50px);
    }

    .project-display-header {

        .project-display-title {
            margin-bottom: 1rem;
        }

        .project-display-category {
            margin-bottom: 2rem;
            font-size: 14px;

            @media screen and (min-width: $tablet-width){
                font-size: 24px;
            }
        }

    }

    .project-display-content {

        .arrow-down {
            display: none;
        }

        .project-display-description {
            font-size: 1rem;
            margin-bottom: 2rem;
        }

    }



    @media screen and (min-width: $desktop-width) {
        .project-display-content {
            display: flex;
            justify-content: space-between;
            column-gap: 5rem;

            .img-wrapper {
                display: inline-block;
                max-width: 55%;
                min-width: 50%;
                display: flex;
                flex-direction: column;
            }

            .item {
                position: relative;
                max-width: 45%;

                .item-inner {
                    position: sticky;
                    top: 6rem;
                }

                .arrow-down {
                    display: block;
                    font-size: 12rem;
                    writing-mode: vertical-rl;
                    line-height: 5rem;
                    padding-left: 2rem;
                }

            }

        }
    }
}

//PROJECT PREVIEW IN HOME

.project-card.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}

.identity {
    margin-bottom: 3rem;

    .identity-title {
        border-bottom: $main-border;
        display: inline-block;
        margin-bottom: 2rem;
    }

    .identity-card {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 1rem;

        h3 {
            font-weight: 700;
        }

        h4 {
            font-weight: 300;
        }

        @media screen and (min-width: $desktop-width) {
            flex-direction: row;
            flex-wrap: wrap;
            row-gap: 0;
            padding-right: 1rem;

        }
    }

    @media screen and (min-width: $desktop-width) {}
}

// CUSTOM RESTRICTION FOR DISPLAY ITEMS IN PROJECT LIST


.project-display-content {

    .external-link {
        display: none;
        position: relative;

        // a {
        //     display: inline-block;
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     left: 0;
        //     top: 0;
        // }
    }

    iframe {
        display: none;
    }

    video {
        display: none;
    }
}

.restriction {

    .project-display-hero:nth-child(5),
    .project-display-hero:nth-child(6) {
        display: none;

    }
}

.with-video {

    iframe {
        display: block;
    }
}

.only-video {

    .project-display-hero {

        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            display: none;
        }

        &:nth-child(2) {
            margin-bottom: 5rem;
        }

    }

    iframe {
        display: block;
        aspect-ratio: 16/9;
    }

    video {
        display: block;
        aspect-ratio: 16/9;
    }
}

.external-link {
    font-weight: 700;
    display: inline-block;
    margin-bottom: 2rem;
    font-size: 1.25rem;
    border: $main-border;
    padding: 1rem;

    @media screen and (min-width: $desktop-width) {
        margin-bottom: 0;
        // padding-top: 3rem;
        // padding-left: 5rem;
        font-size: 2rem;
        padding: 1.5rem;
        margin-top: 2rem;

    }

    &:hover {
        color: $red;
    }
}

.has-external-link {

    .external-link {
        display: inline-block;
    }
}

.external-website {

    .project-display-hero {

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
            display: none;
        }
    }

    .arrow-down {
        margin-bottom: 3rem;
    }

    .external-link {
        display: inline-block;
    }

}

.with-website {

    .arrow-down {
        margin-bottom: 3rem;
    }

    .external-link {
        display: inline-block;
    }

}

// CONTROL THE NAVIGATION PROJECT LIMITS



.overlay-between {
    position: fixed;
    background: $red;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 0;
}

.loading-background {
    // background: $light-color;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: $light-color;
}

.loading-logo-rounded {
    width: 100px;
    height: 100px;
    border: $main-border;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 0;
        background: $red;
        animation: loadingStraight 1.5s cubic-bezier(1,0,0,1);
    }
}

.loading-logo {
    width: 40%;
    height: 36px;
    border: $main-border;
    position: relative;

    &::after{
        content: '';
        position: absolute;
        height: 100%;
        background: $red;
        left: 0;
        top: 0;
        animation: loading 1.5s ease-in-out;
    }
}

@keyframes circled {
    0% {
        height: 0;
    }
    25% {
        height: 50%;
    }
    50% {
        height: 65%;
    }
    75% {
        height: 75%;
    }
    100% {
        height: 100%;
    }
}

@keyframes loadingStraight {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}

@keyframes loading {
    0% {
        width: 0;
    }
    25% {
        width: 50%;
    }
    50% {
        width: 65%;
    }
    75% {
        width: 75%;
    }
    100% {
        width: 100%;
    }
}