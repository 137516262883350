//VARIABLES

$top-margin: 4rem;


//padding top for the HEADER components to center the title
$top-padding: 9rem;

$red: #E55251;
$dark-blue: #1D232D;
$light-color: #fff;

$main-radius: 1rem;
$main-border: .5px solid $red;
$main-shadow: 0 0px 18px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 6%);

//MEDIA QUERIES
$tablet-width: 768px;
$desktop-width: 1024px;

$linear: linear-gradient(90deg, rgba(0,0,0,0) 30%, $dark-blue 100%);