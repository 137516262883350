@import '../variables.scss';

.cursor {
    width: 3rem;
    height: 3rem;
    // background: $red;
    position: fixed;
    // transform: translate3d(0, 0, 0);
    pointer-events: none;
    mix-blend-mode: difference;

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // transition: transform 300ms cubic-bezier(.8,0,.24,1);
    z-index: 1002;
    opacity: 0;    
    transition: transform .6s cubic-bezier(.11,.02,0,1.83);

    //CHILDREN OF THE CURSOR THAT CONTROLS ALL THE CURSOR ANIMATION TRANSFORM

    .cursor-content {
      position: absolute;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      outline: .5px solid $red;
      // transform: rotate(-270deg);
      transform: rotate(0);
      transition: .8s cubic-bezier(.11,.02,0,2.33);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1rem;

      .cursor-title {
        font-size: .5rem;
        text-align: center;
        color: $dark-blue;
        opacity: 0;
        transition: .8s cubic-bezier(.11,.02,0,2.33);;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: $red;
        top: 50%;
        border-radius: 50%;
        // opacity: 0;
        transition: .3s cubic-bezier(1,-0.01,.39,.88);
      }
  
      &::before {
        transform: translateY(-50%) rotate(90deg) scale(.3);

      }
  
      &::after{
        transform: translateY(-50%) rotate(-90deg) scale(.3);
      }
    }

    .is-squared {
      border-radius: 0;
      // transition: .6s cubic-bezier(.11,.02,0,1.83);
      transform: rotate(225deg);
    }
    
    .is-circled {
      transform: scale(2);

      .cursor-title {
        opacity: 1;
      }

      &::before,
      &::after {
        display: none;
      }
      
    }
  }

  .is-crossed {
    
    .cursor-content {
      transform: rotate(-270deg);

      &::before,
      &::after {
        opacity: 1;
        height: 0.5px;
      }
  
      &::before {
        transform: translateY(-50%) rotate(45deg) scale(.8);
      }
  
      &::after{
        transform: translateY(-50%) rotate(-45deg) scale(.8);
      }
    }
  }

  .secondary-cursor {
    position: fixed;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    border: $main-border;
    z-index: 1002;
    pointer-events: none;
    opacity: 0;
    transition: transform cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  .little {
    transform: scale(.5);
  }

  .little ~ .secondary-cursor {
    transform: scale(.5);
  }

  // @keyframes bigger {
  //   0% {
  //     transform: scale(1);
  //     opacity: 1;
  //   }

  //   75% {
  //     transform: scale(6);
  //   }

  //   100% {
  //     transform: scale(1);
  //     opacity: 0;
  //   }
  // }

  @media screen and (min-width: $desktop-width){
    .cursor,
    .secondary-cursor {
      opacity: 1;
    }
  }