@import '../variables.scss';

.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 98;
    height: 60px;
    padding: 0 1rem;

    
    .nav-overlay {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $light-color;
        z-index: 99;
        // transition: .3s ease-out;
        transform: translateX(100);
    }
}

.nav.is-active {
    transform: translateX(0);
}

 // sidebar navigation
      
 .NavigationOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    z-index: 8;
    transform: translateX(105%);
    cursor: none;
    transition: .3s ease-out;

    // &:hover ~ .menu-wrapper {
    //   box-shadow: -10px 0 600px $dark-blue;
    // }
  }
  
  .menu-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    // display: flex;
    z-index: 9;
    width: 300px;
    right: 0;
    overflow: hidden;
    transform: translateX(100%);
    transition: .3s ease-out;
  }
  
  .menu-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    // right: 0;
    margin: auto;
    display: flex;
    height: 100%;
    width: 100%;
    background: $red;
    z-index: -1;
    transform: translateX(100%);
  }
  
  .sidebarNavigation {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $light-color;
    border: $main-border;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
    overflow-y: auto;
    row-gap: 2rem;
    padding: 0 3rem;
    transform: translateX(100%);

    &::-webkit-scrollbar {
      width: 0px;
    }


    .link-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .menu-link {
    height: 4rem;
    width: 100%;
    color: $red;
    text-transform: uppercase;
    font-weight: 500;
    border: $main-border;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: none;
    position: relative;
    opacity: 0;
    transform: translateY(25%);
    font-weight: 700;
    transition: .1s ease-out;

    .menu-link-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s ease-out;
        cursor: none;
    }

      &:hover {
        border: 3px solid $red;
        letter-spacing: 3px;
      }
    }
  }

@media (min-width: $tablet-width){
  .sidebarNavigation {

    .socials-wrapper {
      display: block;
    }
  }

    .menu-wrapper {
        width: 600px;
    }
}

.socials-wrapper {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  display: none;
}