@import '../variables.scss';

.project-navigation.navigation-0 {
    .project-navigation-inner {
         justify-content: center;
        .next-project {
            display: none;
        }
    }
}

.project-navigation.navigation-10 {
    .project-navigation-inner {
        justify-content: center;
        .previous-project {
            display: none;
        }
    }
}

.nav-anime {
    transform: translateY(10px);
    opacity: 0;
}

.project-navigation {
    display: flex;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 1rem;
    z-index: 1000;
    justify-content: center;

    .arrow-invert {
        font-size: 1.5rem;
    }

    .project-navigation-inner {
        width: 100%;
        border: .5px solid rgba(128, 128, 128, 0.5);
        background: $light-color;
        border-radius: $main-radius;
        box-shadow: $main-shadow;
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;

        @media screen and (min-width: $tablet-width){
            width: 75%;
    }

        .navigation-wrapper {
            font-size: .75rem;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
    
            @media screen and (min-width: $tablet-width){
        
                    padding: 1rem;
                    font-size: 1rem;
            }
    
            .navigation-content {
                display: flex;
                align-items: center;
                gap: 1.125rem;
    
                .invert {
                    transform: rotate(180deg);
                }
            }
        }
    }
    
}
