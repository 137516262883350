@import 'variables.scss';

* {
  margin: 0;
  box-sizing: border-box;
}

a,
a:visited,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
  cursor: none;
}

ul {
  padding: inherit;
}

li {
  list-style: none;
}

img {
  display: block;
  width: 100%;
}

body {
  font-family: 'poppins';
  padding-top: $top-margin;
  padding-left: .5rem;
  padding-right: .5rem;
  overflow-x: hidden;
  overflow: hidden;
  overflow-y: auto;
  cursor: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: $desktop-width){

    padding-left: 1rem;
    padding-right: 1rem;

}

  // @media screen and (min-width: $desktop-width){

  //     padding-left: 0;
  //     padding-right: 0;

  // }
  
}

::selection{
  background: transparent;
  color: $red;
}

.container-layout {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  
  @media screen and (min-width: $desktop-width){
    padding-top: $top-padding;
  }
}

.container-inner {
  display: inline-block;
}


// GENERAL CLASSES FOR LITTLE COMPONENTS

.text-red {
  color: $red;

  &::selection {
    color: $dark-blue;
  }
}

.arrow-invert {
  background: $light-color;
  font-size: 2rem;
  color: $red;
  cursor: none;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  
  .menu-link-link {
    display: inline-block;
    transform: rotate(180deg);
  }

  @media screen and (min-width: $tablet-width){
    font-size: 3rem;
    background: transparent;
  }
}

.menu-link-link {
  cursor: none;
}

.Credits {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  font-size: 12px;
}

.page-not-found-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    margin-bottom: 2.5rem;
  }

  .notFoundGif {
    max-width: 320px;
    margin-bottom: 5rem;
  }
}

