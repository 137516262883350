@import '../variables.scss';

.Sector {
    color: $dark-blue;

    .sector-app-container {
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.sector-layout {

    @media screen and (min-width: $desktop-width){
        max-width: 1400px;
        margin: 0 auto;
        margin-bottom: 8rem;
    }

    .header-second-title {
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 5rem;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }


    .container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        .item {
            width: 100%;
            height: auto;
            flex-shrink: 0;

            h1 {
                margin-bottom: 2rem;
            }

            .img-container {
                width: 100%;
                background: tomato;
            }
        }

    }

    .sector-container {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem;
        place-items: center;

        @media screen and (min-width: $tablet-width){
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (min-width: $desktop-width){
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }
}

.sector-item {
    position: relative;
    // border: #e6e6e6 1px solid;
    background: $light-color;
    max-width: 300px;
    min-height: 300px;
    width: 300px;
    // padding-top: 3rem;
    // padding-bottom: 3rem;
    border-radius: 10px;
    overflow: hidden;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    box-shadow: 0 0px 18px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px        rgb(0 0 0 / 6%);

    @media screen and (min-width: $tablet-width){
        max-width: 300px;
        min-height: 300px;
    }

    .sector-item-hero {
        position: relative;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .arrow {
        position: relative;
        opacity: 0;
        transition: .2s ease-out;
    }

    .section-item-icon {
        position: absolute;
        width: 30px;
        height: 30px;
        background: $light-color;
        border-radius: 50%;
        left: .5rem;
        bottom: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        position: absolute;
    border-radius: 10px;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .section-item-description{
        padding: 1rem .5rem;
        .sector-item-title {
            font-size: 1rem;
            margin-bottom: .5rem;
        }

        .section-item-text {
            font-size: 14px;
            display: -webkit-box; 
  -webkit-box-orient: vertical;
            overflow: hidden;
            -webkit-line-clamp: 3;
            opacity: .75;
            margin-bottom: 0.5rem;
        }

        .sector-item-date {
            font-size: 12px;
            font-weight: 600;
            opacity: .75;
        }
    }


    .sector-link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 5;
    }
}

.sector-item::before,
.sector-item::after {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 500ms;
  width: 100%;
}

.sector-item::before {
    // background: radial-gradient(
    //   800px circle at var(--mouse-x) var(--mouse-y), 
    //   rgba(15, 16, 69, 0.057),
    //   transparent 70%
    // );
    background: radial-gradient(
        800px circle at var(--mouse-x) var(--mouse-y), 
        rgba(29, 35, 45, 0.09),
        transparent 70%
      );
    z-index: 3;
  }

  .sector-item::after {  
    // background: radial-gradient(
    //   600px circle at var(--mouse-x) var(--mouse-y), 
    //   rgba(0, 0, 0, 0.14),
    //   transparent 40%
    // );
    background: radial-gradient(
        800px circle at var(--mouse-x) var(--mouse-y), 
        rgba(29, 35, 45, 0.19),
        transparent 70%
      );
    z-index: 1;
  }

  .sector-item > .sector-item-content {
    background-color: $light-color;
    border-radius: inherit;
    flex-grow: 1;
    inset: 1px;
    position: absolute;
    z-index: 2;
  }

  .sector-container:hover > .sector-item::after {
    opacity: 1;
  }
  
  .sector-item:hover::before {
    opacity: 1;
  }

.sector-home {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and  (min-width: $desktop-width){
        height: 100vh;
    }

    .sector-home-container {
      display: flex;
      justify-content: center;
    
      @media screen and  (min-width: $tablet-width){
    
      }
    
    //   @media screen and  (min-width: $desktop-width){
    //       max-width: 1400px;
    //       margin: 0 auto;
    //   }
      
    
      .sector-home-link {
          color: $dark-blue;
          font-size: 1.5rem;
          font-weight: 700;
        //   box-shadow: 0 0 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px        rgb(0 0 0 / 6%);
        //   border-radius: 10px;
          padding: 1rem 2rem;
          text-transform: uppercase;
          border: $main-border;
    
          @media screen and  (min-width: $tablet-width){
    
          }
    
          @media screen and  (min-width: $desktop-width){
              font-size: 4rem;
          }
      }
    }
}
