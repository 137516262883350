@import '../variables.scss';

.socials {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 4rem;
    flex-wrap: wrap;
    
    .is-moused {
        opacity: 0;
        transform: translateY(50px);
    }

    .socials-item {
        border: $main-border;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $light-color;

        .socials-icon {
            font-size: 2rem;
            color: $red;
        }
    }
}

.socials-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $dark-blue;
    position: relative;

    .socials-title {
        position: absolute;
        font-size: 4.5rem;
        top: 5rem;
        left: 1rem;
        overflow: hidden;

        .letter-animate {
            display: inline-block;
        }

        @media screen and (min-width: $desktop-width){
            font-size: 10rem;
        }
    }
    
    .is-moused {
        opacity: 1;
        transform: none;
    }

    .socials-item {

        @media screen and (min-width: $desktop-width){
            width: 120px;
            height: 120px;

            .socials-icon {
                font-size: 3rem;
            }
        }

    }
}

.socials-for-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $dark-blue;
    position: relative;

    .socials-title {
        position: absolute;
        font-size: 5rem;
        top: 5rem;
        left: 1rem;
        overflow: hidden;

        .letter-animate {
            display: inline-block;
        }

        @media screen and (min-width: $desktop-width){
            font-size: 5rem;
        }
    }
    
    .is-moused {
        opacity: 1;
        transform: none;
    }

    .socials-item {


        @media screen and (min-width: $desktop-width){
            width: 80px;
            height: 80px;

            .socials-icon {
                font-size: 2rem;
            }
        }

    }
}

.contact-more {
    font-size: 2rem;
    font-weight: 700;
    color: $red;

    @media screen and (min-width: $desktop-width){
        font-size: 3rem;
        overflow: hidden;
        padding: 0;

        .disappear-appear {
            display: inline-block;
            transform: translateY(55px);
            transition: 100ms ease-out;
            opacity: 0;
        }

        &:hover {

            .disappear-appear {
            opacity: 1;
            transform: translateY(0);
            }
        }
    }
}

.socials-item {
    position: relative;

    &::before {
        content: '';
        width: 100%;
        height: 0;
        background: $red;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: .5s cubic-bezier(1,0,0,1);
        z-index: 1;
    }

    .socials-icon {
        z-index: 2;
        transition: .5s cubic-bezier(1,0,0,1);
    }

    &:hover {
        
        .socials-icon {
            color: $light-color;
        }

        &::before {
            height: 100%;
        }
    }
}