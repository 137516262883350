@import '../variables.scss';

.header-hero.sector {
    margin-bottom: 5rem;
}

.header-hero {
    overflow: hidden;
    position: relative;
    z-index: 2;
    color: $dark-blue;
    margin-bottom: 6rem;
    font-size: 1vmin;

    .header-title {
        margin-bottom: 2rem;
    }
    
    @media screen and (min-width: $tablet-width){
        margin-bottom: 12rem;
    }

    .contact-button {
        opacity: 0;
        transform: translateY(50px);
        font-size: 16px;
        font-weight: 700;
        padding: .5rem;
        border: $main-border;
        display: flex;
        justify-content: center;
        position: relative;
        gap: .5rem;

        &:hover {

            .contact-button-overlay {
            transform: scaleX(1);

            }

        }

        .contact-button-overlay {
            background: $red;
            position: absolute;
            width: 100%;
            mix-blend-mode: difference;
            height: 10px;
            bottom: 0;
            transform: scaleX(0);
            transform-origin: left;
            transition: .3s ease-in-out;
        }

        @media screen and (min-width: $tablet-width){
            font-size: 2rem;
            padding: 1rem;
        }
    }

        br {
        display: none;
    }
    
    h1 {
        color: $dark-blue;
        text-transform: uppercase;
        // margin-bottom: 2rem;
        font-size: 13em;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // text-align: center;
        // -webkit-hyphens: manual;

        .text-red {
            display: inline-block;

            br {
                display: inline-block;
            }
        }
        
    }

    .header-hero-status {
        font-weight: 700;
        font-size: 3em;
        // font-size: 1rem;
        color: $dark-blue;
        display: flex;
        justify-content: space-between;
        // flex-direction: column;

        .status {
            opacity: 0;

            &:nth-child(1){
                transform: translateX(-100%);;
            }

            &:nth-child(2){
                transform: translateX(100%);
                align-self: end;
                text-align: right;
            }
        }

        br {
            display: inline-block;
        }
    }
}

    @media (min-width: $tablet-width){
        .header-hero {

            .header-hero-status {
                // flex-direction: row;
                // font-size: 1.5em;
            }


            h1 {
                // font-size: 5.5rem;
                // .username,
                // .is-appear {
                //     display: inline-block;
                // }
            }
        }
    }


    @media (min-width: $desktop-width){
        .header-hero {
            // margin-left: 15rem;
            // margin-top: 12rem;

            .header-title {
                // margin-bottom: 4rem;
            }


            // br {
            //     display: inline-block;
            // }

            h1 {
                // font-size: 7em;
                // display: inline-block;
                // .username,
                // .is-appear {
                //     display: inline-block;
                // }

                .tiret {
                    display: none;

                    // br {
                    //     display: none;
                    // }
                }
            }
        }
    }


//HEADER WITH BURGER


.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
    height: $top-margin;
    padding: 0 1rem;
    background: $light-color;
    // box-shadow: 0 0 40px rgb(29, 35, 45, .25);

    .brand-icon a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 900;
        text-transform: uppercase;
        color: $dark-blue;
        cursor: none;
        font-size: 1rem;
    }
    .brand-icon .icon {
        display: inline-flex;
        font-size: 2rem;
        height: 48px;
        width: 48px;
        justify-content: center;
        align-items: center;
    }
    .burger-trigger {
        display: flex;
        flex-direction: column;
        height: 40px;
        width: 64px;
        align-items: center;
        justify-content: space-evenly;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        flex-shrink: 0;
        z-index: 100;
        // overflow: hidden;
        cursor: none;
        
    @media (min-width: $tablet-width){
        width: 48px;
    }

        .bars {
            width: 100%;
            height: 8px;
            background: $red;
            transition: .3s ease-out;
            transition-delay: .35s;
            transform: translateX(0);
            flex-shrink: 0;
        }
        // .arrow {
        //     opacity: 0;
        //     transition: .3s ease-out;
        //     display: none;
        // }
        .arrow {
            transform: translateX(103%);
        }
    }
    .burger-close {
        // .bars {
        //     transform: translateX(-120%);
        // }
        // .arrow {
        //     display: block;
        //     font-size: 3rem;
        //     line-height: 5px;
        //     opacity: 1;
        //     color: $red;
        // }
        .arrow {
            transform: translateX(0);
        }
    }

    @media screen and (min-width: $tablet-width){
        background: transparent;
    }
}
.arrow {
 position: absolute;
     font-size: 3rem;
     color: $red;
     transition: .3s ease-out;
     transition-delay: .35s;

    //  transform: translateX(100%);
     // transform: translateX(0);
 }

 .is-appear {
    opacity: 0;
    transform: translateY(70px);
}

.burger-menu-indicator {
    font-weight: 400;
    font-size: 0.75rem;
    position: absolute;
    left: -2.5rem;
    color: $red;
    opacity: 75%;
}

// ABOUT PAGE

.About {

    .header-about {
        margin-bottom: 12rem;

        @media screen and (min-width: $desktop-width){
            margin-bottom: 25rem;
        }

        .header-title {
            margin-bottom: 0;
        }
    }
}