@import '../variables.scss';

.About {
    color: $dark-blue;

    .header-hero {
        position: relative;
        overflow: visible;

        // @media screen and (min-width: $tablet-width){
        //     margin-bottom: 20rem;
        // }

        .header-hero-portrait {
            position: absolute;
            width: 50%;
            height: 50%;
            top: 5rem;
            z-index: -1;
            right: 0;

            @media screen and (min-width: $desktop-width){
                width: 400px;
                height: 400px;
                top: 3rem;
                right: -10rem;
            }

        }
    }

    .about-section {
        margin-bottom: 3rem;
        opacity: 0;
        transform: translateY(100px);
        transition: .3s ease-in-out;
        
        @media screen and (min-width: $desktop-width){
            display: flex;
            max-width: 1400px;
            margin: 0 auto;
            justify-content: space-between;
            margin-bottom: 12rem;
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            display: inline-block;
            margin-bottom: 2rem;
            line-height: 16px;


            @media screen and (min-width: $desktop-width){
                font-size: 4rem;
                margin-bottom: 4rem;
                line-height: 28px;
            }
        }

        .description-content {
            font-size: 1rem;

            @media screen and (min-width: $desktop-width){
                font-size: 22px;
                max-width: 50%;
                // padding-left: 4rem;
            }
        }
        

        .skills-component {
            display: flex;
            flex-direction: column;
            row-gap: 2rem;

            @media screen and (min-width: $desktop-width){
                padding-left: 4em;
            }

            .skills-wrapper {
                display: flex;
                column-gap: 2rem;
    
                .skills-arrow {
                    color: $red;
                    font-size: 24px;

                    @media screen and (min-width: $desktop-width){
                        font-size: 3.5rem;
                    }
                }
    
                .skills-content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: $main-border;
                    width: 100%;
                    font-weight: 700;
                    padding: 0 1rem;
                    background: $light-color;


                    @media screen and (min-width: $tablet-width){
                        font-size: 2rem;

                        .text-red {
                            font-size: 1.5rem;
                        }
                    }

                    @media screen and (min-width: $desktop-width){
                        width: 600px;
                    }
    
                    .text-red {
                        font-weight: 300;
                        opacity: .75;
                    }
                }
            }
        }

        .services-component {

            .services-item {
                font-size: 1rem;

                @media screen and (min-width: $desktop-width){
                    font-size: 2rem;
                    font-weight: 400;
                }
            }
        }
    }

    .about-section.services-section {

        // ul {
        //     margin-bottom: 1rem;
        // }

        .services-component {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;
            gap: 3rem;

            .service-item {

            }
        }
        
        @media screen and (min-width: $desktop-width){
            margin-bottom: 5rem;
            flex-direction: column;

            ul {
                margin-bottom: 0;
            }

            .services-component {
                margin-top: 5rem;
                gap: 5rem;

                .service-item {

                    .service-item-title {
                        font-size: 2.5rem;
                    }

                    ul {
                        padding-left: 2.5rem;
                        font-size: 22px;
                    }
                }

            }
        }
    }
}

.about-section.active {
    transform: translateY(0);
    opacity: 1;
    pointer-events: all;
}